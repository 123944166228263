import Config from "./modules/Config"
import { makeElement, addMorePopup, getCountry, getCart, getCookie } from "./Utils"


const pageName = window.location.pathname
let reviews_slider


const showReviews = () => {
  let reviews = document.querySelector('.reviews')
  if (!reviews) {
    reviews = makeElement('<div id="reviews" class="reviews light-hidden hidden"></div>')
    document.body.appendChild(reviews)
  }
  reviews.setAttribute('id', 'reviews')
  reviews.innerHTML = `
    <div class="container">
      <div class="reviews_in">
        <h3>Ваши отзывы и победы</h3>
        <p>Необязательно верить нам на слово, посмотрите на результаты наших клиентов.</p>
        <div class="reviews_slider"></div>
      </div>
      <div class="reviews_nav"></div>
    </div>
    `
}

const addReview = (r) => {
  if (!r.enabled)
    return

  let contains = false
  for (const page of r.pages) {
    if (page.url == 'all' || page.url == window.location.pathname)
      contains = true
  }
  if (!contains)
    return

  const reviewsContainer = document.querySelector('.reviews')
  if (!reviewsContainer.dataset.alwayshidden) {
    reviewsContainer.classList.remove('hidden')
  }

  const reviews = document.querySelector('.reviews_slider')
  const reviewsNav = document.querySelector('.reviews_nav')
  const newReview = document.createElement('div')
  newReview.classList.add('reviews_slider_inner')

  const isSet = (r.product.group == "Набор")
  const productName = isSet ? `${r.product.name} ${r.product.description}` : r.product.name
  const productDescription = isSet ? r.product.html_short_description : r.product.description

  const aboutBtn = isSet ? `<a class="reviews-btn" data-fancybox="" data-src="#review_more_popap${r.product.id}" href="javascript:;" onclick="sessionStorage.from_review=1;$.fancybox.close()">Подробнее о наборе</a>` : `<a class="reviews-btn" onclick="sessionStorage.from_review=1" href="${r.product.details_url}">Подробнее о продукте</a>`
  const productPart = `
        <div class="reviews_in_block3">
            <p><span>Что поможет добиться таких же результатов:</span> </p>
            <div class="reviews_in_block3_flex">
              <div class="reviews_in_block3_flex_img">
                <img loading="lazy" src="${r.product.round_image_url}" alt="">
              </div>
              <div class="reviews_in_block3_flex_txt">
                <p class="reviews_in_block3_flex_txt_top">${productName}</p>
                <p class="reviews_in_block3_flex_txt_mid">${productDescription}</p>
                ${aboutBtn}
              </div>
            </div>
        </div>
    `
  let reviewImageOrVideoPart
  if (r.is_video) {
    const url = new URL(r.review_video_url)
    const video_id = url.searchParams.get('v') || url.pathname.slice(1)
    reviewImageOrVideoPart = `<a class="youtube-video" data-fancybox-type="iframe" data-fancybox href="${r.review_video_url}">
        <img src="https://i.ytimg.com/vi/${video_id}/mqdefault.jpg" width="370" height="250">
      </a>`
  } else {
    reviewImageOrVideoPart = `<img loading="lazy" src="${r.review_image}" alt="">`
  }
  newReview.innerHTML = `
        <div class="reviews_slider_inner_in">
          <div class="reviews_in_block1">
            <div class="review-ava">
              <img loading="lazy" src="${r.client_image}" alt="">
            </div>
            <div class="review-ava_txt">
              <p class="review-ava_txt_in1"><b>ДО: </b>${r.before_text}</p>
              <p class="review-ava_txt_in2"><b>после: </b>${r.after_text}</p>
            </div>
          </div>
          <div class="reviews_in_block2">
            ${reviewImageOrVideoPart}
          </div>
          ${pageName.endsWith('landing_sales.html') ? '' : productPart}
        </div>
    `

  const newNav = document.createElement('div')
  newNav.dataset.nav_review_id = r.id
  reviewsNav.appendChild(newNav)

  newReview.dataset.review_id = r.id
  reviews.appendChild(newReview)
}

(() => {
  const apiHost = Config.apiUrl
  fetch(apiHost + '/reviews')
    .then(r => r.json())
    .then(d => {
      if (d) {
        showReviews()
      }
      d.forEach(r => addReview(r))

      let slider = $('.reviews_slider')
      const ajustSliderHeight = () => {
        const reviewsNav = document.querySelector('.reviews_nav')
        Array.from(reviewsNav.children).forEach(n => n.classList.remove('active'))
        let maxHeight = 0
        const activeSlides = slider.find('.slick-active')
        for (let i = 0; i < activeSlides.length; i++) {
          const currentSlide = activeSlides[i]
          if (currentSlide.offsetHeight > maxHeight) {
            maxHeight = $(currentSlide).height()
          }
          document.querySelector(`*[data-nav_review_id="${currentSlide.dataset.review_id}"]`).classList.add('active')
        }
        document.querySelector('.reviews_slider > .slick-list').style.height = maxHeight + 'px'
      }

      reviews_slider = slider.slick({
        lazyLoad: 'ondemand',
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 1,
              arrows: true,
            }
          },
        ],
      });

      document.querySelectorAll('.reviews_slider .slick-arrow').forEach((e) => {
        e.addEventListener('click', () => {
          ym(57109408, 'reachGoal', 'review_slide')
        })
      })

      setInterval(ajustSliderHeight, 250)
    })
  const data = {
    "cart": getCart(),
    "country": getCountry(),
    "promo": null,
    "kindergift": false,
    "test_group": getCookie('test_group'),
  }
  fetch(apiHost + "/prices", {
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify(data),
  }).then(r => r.json())
    .then(d => {
      d.products.forEach(p => addMorePopup(p, true))
    })


  let sideReviews = document.querySelector('.side-reviews')
  if (!sideReviews) {
    sideReviews = makeElement(`
      <div class="side-reviews">
        <img src="/static/img/star.svg">
        <span><b>5 </b><u>Посмотреть отзывы</u></span>
      </div>`)
    document.body.appendChild(sideReviews)
  }
  if (sideReviews) {
    setInterval(() => {
      const reviews = document.querySelector('#reviews')
      if (!reviews || reviews.classList.contains('hidden')) {
        sideReviews.classList.add('hidden')
      } else {
        sideReviews.classList.remove('hidden')
      }
    }, 150)
    sideReviews.addEventListener('click', () => {
      const reviews = document.querySelector('#reviews')
      reviews.classList.add('reviews-in-popup')

      $.fancybox.open({
        src: '#reviews',
        type: 'inline',
        afterClose: function (instance, slide) {
          $(slide.src).prop("style", "");
          reviews.classList.remove('reviews-in-popup')
          $('.reviews_slider').slick("setOption", '', '', true);
        },
        afterShow: function (instance, slide) {
          $('.reviews_slider').slick("setOption", '', '', true);
          setTimeout(() => $('.reviews_slider').slick("setOption", '', '', true), 100)
          setTimeout(() => $('.reviews_slider').slick("setOption", '', '', true), 200)
        },
      })

      ym(57109408, 'reachGoal', 'feeedback_widjet')
    })
  }
})()
