import Popup from "./modules/Popup";
import autoComplete from "@tarekraafat/autocomplete.js";
import countryCookieManager from "./modules/CountryCookieManager";
import Config from "./modules/Config";
import CartManager from "./modules/CartManager";

document.addEventListener("DOMContentLoaded", function () {
  var params = new URLSearchParams(document.location.search);
  var add = params.get("add");
  if (add) {
    var cart = CartManager.getBasketItemsFromCookie();
    var added = false;
    for (const i in cart) {
      if (cart[i].id == add) {
        cart[i].quantity += 1;
        added = true;
        break;
      }
    }
    if (!added) {
      cart.push({ id: add, quantity: 1 });
    }
    Cookies.set("basket", JSON.stringify(cart));
    params.delete(add);
    window.location.replace(window.location.origin + window.location.pathname + "?" + params);
  }
  // if (params.get("successPay")) {
  //     alert("successPay");
  // }
  // if (params.get("failedPay")) {
  //     alert("failedPay");
  // }

  const axios = require("axios").default;
  localStorage.clear();

  //autocomplete

  const countryList = document.querySelector(".search-result");
  initAutocompletePlugin().catch((e) => console.log(e));

  async function initAutocompletePlugin() {
    const countries = await getCountries();
    if (countries.length) {
      const autoCompletePlugin = new autoComplete({
        selector: ".search__input",
        data: {
          src: countries,
        },
        resultsList: false,
      });

      showCountriesList(countries, true);

      let searchResult = [];

      document
        .querySelector(`${autoCompletePlugin.selector}`)
        .addEventListener("results", function (event) {
          const resultsArray = event.detail.results.map((result) => result.value);
          console.log(resultsArray.length);
          const noResult = resultsArray.length === 0;
          searchResult = noResult ? [] : [...resultsArray];
        });

      const searchButton = document.querySelector(".search__button");
      const searchInput = document.querySelector(".search__input");

      searchInput.addEventListener("input", (e) => {
        setTimeout(() => {
          if (!searchResult.length) {
            renderSearchError();
          } else {
            showCountriesList(searchResult);
          }
        });
      });

      searchButton.addEventListener("click", (e) => {
        setTimeout(() => {
          if (!searchResult.length) {
            renderSearchError();
          } else {
            showCountriesList(searchResult);
          }
        });
      });
    }
  }

  async function getCountries() {
    const url = Config.apiUrl + "/countries";
    const response = await axios.get(url);
    return response.data;
  }

  function showCountriesList(countries, firstLoad = false) {
    countryList.innerHTML = "";
    if (firstLoad) {
      countries
        .filter((country) => country.preview)
        .forEach((previewCountry) => renderCountriesList(previewCountry));
    } else {
      countries.forEach((country) => renderCountriesList(country));
    }
  }

  function renderCountriesList(countryData) {
    const countryItem = document.createElement("li");
    countryItem.classList.add("search-result__item");
    countryItem.innerHTML = `<a href="#" data-country="${countryData}">${countryData}</a>`;

    setActiveCountryInPopup(countryItem);

    countryItem.addEventListener("click", countryItemHandler);

    if (countryItem.classList.contains("current")) {
      countryList.prepend(countryItem);
    } else {
      countryList.append(countryItem);
    }
  }

  function countryItemHandler() {
    const country = this.querySelector("a").dataset.country;

    countryCookieManager.setCountryInCookie(country);
    CartManager._receiveProducts();
    setCountryInHeader(country);
    countriesPopup.close();
    location.reload();
  }

  function setCountryInHeader(countryName) {
    const countryTextElement = document.querySelector(".location__text");
    countryTextElement.textContent = countryName;
    const countrySpan = document.querySelector(".current-country");
    countrySpan.textContent = countryName;
  }

  function setActiveCountryInPopup(countryItem) {
    const currentCountry = document.querySelector(".location__text").textContent;
    return countryItem.textContent === currentCountry ? countryItem.classList.add("current") : null;
  }

  function renderSearchError() {
    countryList.innerHTML = "";
    const errorItem = document.createElement("li");
    errorItem.classList.add("error", "search-result__item");
    errorItem.textContent = "Нет совпадений";

    countryList.prepend(errorItem);
  }

  const locationPopupElem = document.querySelector(".location-popup");
  const locationPopup = new Popup({
    elemSelector: ".location-popup",
    //если страна не установлена в куках
    showOnPageLoad: !countryCookieManager.isCountryInCookies(),
    showOnce: true,
  });

  const countriesPopup = new Popup({
    elemSelector: ".popup",
    openByElementSelector: ".location",
    wrapper: true,
  });

  function setLocation() {
    if (countryCookieManager.isCountryInCookies()) {
      setCountryInHeader(countryCookieManager.getCountryFromCookies());
    } else {
      // устанавливаем значение по дефолту
      fetch(Config.apiUrl + "/location-by-ip")
        .then((data) => data.json())
        .then((data) => {
          const confirmButton = document.querySelector(".country-confirm");
          const rejectButton = document.querySelector(".country-reject");

          setCountryInHeader(data.country);
          countryCookieManager.setCountryInCookie(data.country);

          confirmButton.addEventListener("click", () => {
            locationPopup.close(true);
          });
          rejectButton.addEventListener("click", () => {
            locationPopup.close();
            countriesPopup.open();
          });
        })
        .catch((e) => console.log(e));
    }
  }

  setLocation();

  const locationButtons = document.querySelectorAll(".location");
  const burgerButton = document.querySelector(".js-sidenav-toggle");

  if (locationButtons.length) {
    locationButtons.forEach((location) => location.addEventListener("click", closeSideMenu));
  }

  this.addEventListener("initSlider2", () => {
    if (burgerButton) {
      burgerButton.addEventListener("click", function () {
        locationPopup.close();
        const burgerIsOpened = burgerButton.classList.contains(".js-side-navigation");
        if (burgerIsOpened) {
          closeSideMenu();
        } else {
          openSideMenu();
        }
      });

      const burgerCloseBtn = document.querySelector(".b-side-navigation__close-txt");
      const sideMenuWrap = document.querySelector(".js-side-navigation");

      burgerCloseBtn.addEventListener("click", closeSideMenu);
      sideMenuWrap.addEventListener("click", (e) => {
        const clickOnWrap = e.target.classList.contains("js-side-navigation");
        if (clickOnWrap) {
          closeSideMenu();
        }
      });
    }
  });

  function openSideMenu() {
    const body = document.body;
    const sideMenu = document.querySelector(".js-side-navigation");

    body.classList.add("_menu_opened");
    burgerButton.classList.add("toggle-menu-btn--opened");
    sideMenu.classList.add("_opened");
  }

  function closeSideMenu() {
    const body = document.body;
    const sideMenu = document.querySelector(".js-side-navigation");

    body.classList.remove("_menu_opened");
    burgerButton.classList.remove("toggle-menu-btn--opened");
    sideMenu.classList.remove("_opened");
  }
});
