import { showCloudpayments, api } from "./Utils"

const btn = document.querySelector('.btn_1')
const passCheckbox = document.querySelector('#order-cfi')
const promoCheckbox = document.querySelector('#order-promo')

promoCheckbox.addEventListener('click', () => {
    btn.href = btn.href.slice(0, -1) + (promoCheckbox.checked ? 1 : 0)
})

btn.addEventListener('click', (e) => {
    if (!passCheckbox.checked) {
        passCheckbox.style.borderColor = '#f00'
        e.preventDefault()
    }
})

document.addEventListener('open-cloudpayments', () => {
    const params = new URLSearchParams(window.location.search)
    const leadId = params.get('lead_id')
    const isQr = params.get('is_qr')

    api('get', `/lead-info-for-pay?lead_id=${leadId}&is_qr=${isQr ? true : false}`).then((d) => {
        showCloudpayments(d.public_id, leadId, d.full_price, d.is_en, true)
    })
})
