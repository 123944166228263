import Config from "./modules/Config"

(() => {
    const ids = Config.pageProductIds

    const getCookie = (name) => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2)
            return decodeURIComponent(parts.pop().split(';').shift())
    }

    const setCookie = (name, value) => {
        value = encodeURIComponent(value)
        const days = 365
        var expires = ""
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/"
    }

    const getCart = () => {
        const cartRaw = getCookie("basket")
        var cart = []
        if (cartRaw)
            cart = JSON.parse(cartRaw)
        return cart
    }

    const addCartItem = (id) => {
        var cart = getCart()
        var isAdded = false
        for (const i in cart) {
            if (cart[i].id == id) {
                cart[i].quantity += 1
                isAdded = true
                break
            }
        }
        if (!isAdded) {
            cart.push({ id: id, quantity: 1 })
        }
		window["rrApiOnReady"] = window["rrApiOnReady"] || []
		window["rrApiOnReady"].push(function() {
			try { rrApi.addToBasket(id); } catch(ex) {}
		})
		try {
			const price = document.querySelector(`div[data-cart_line_id="${id}"]`).querySelector('.js-checkout__price').textContent
			_tmr.push({ type: "reachGoal", id: 3384650, value: price, goal: "addToWishlist", params: { product_id: id}});
			_tmr.push({ type: "reachGoal", id: 3384650, value: price, goal: "addToCart", params: { product_id: id}});
		} catch (e) {}
        setCookie('basket', JSON.stringify(cart))
    }

    const subCartItem = (id) => {
        var cart = getCart()
        for (const i in cart) {
            if (cart[i].id == id) {
                cart[i].quantity -= 1
                if (cart[i].quantity < 0) {
                    cart[i].quantity = 0
                }
                break
            }
        }
        setCookie('basket', JSON.stringify(cart))
    }

    const setCartItem = (id, q) => {
        var cart = getCart()
        var isSeted = false
        for (const i in cart) {
            if (cart[i].id == id) {
                cart[i].quantity = q
                isSeted = true
                break
            }
        }
        if (!isSeted) {
            cart.push({ id: id, quantity: q })
        }
        setCookie('basket', JSON.stringify(cart))
    }

    const addToCart = (e) => {
        const attr = e.srcElement.getAttribute('data-product-id')
        const id = attr ? parseInt(attr) : 0
        if (id) {
            addCartItem(id)
            return
        }
        for (const page of Object.keys(ids)) {
            if (window.location.pathname.includes(page)) {
                addCartItem(ids[page])
                break
            }
        }
    }

    const subFromCart = (e) => {
        const attr = e.srcElement.getAttribute('data-product-id')
        const id = attr ? parseInt(attr) : 0
        if (id) {
            subCartItem(id)
            return
        }
        for (const page of Object.keys(ids)) {
            if (window.location.pathname.includes(page)) {
                subCartItem(ids[page])
                break
            }
        }
    }

    const setToCart = (e) => {
        const attr = e.srcElement.getAttribute('data-product-id')
        const id = attr ? parseInt(attr) : 0
        const q = e.srcElement.value
        if (id) {
            setCartItem(id, q)
            return
        }
        for (const page of Object.keys(ids)) {
            if (window.location.pathname.includes(page)) {
                setCartItem(ids[page], q)
                break
            }
        }
    }

    addEventListener('productsFetched', () => {
        const orderButtons = [...document.querySelectorAll('.js-card-order__button, .js-product-buy, .b-quantity__btn--up')]
        orderButtons.forEach((e) => {
            e.addEventListener('click', addToCart)
        })

        const subs = [...document.querySelectorAll('.b-quantity__btn--down')]
        subs.forEach((e) => {
            e.addEventListener('click', subFromCart)
        })

        const sets = [...document.querySelectorAll('input[class="js-checkout__quantity b-quantity__input"]')]
        sets.forEach((e) => {
            e.addEventListener('click', setToCart)
        })
    })
})()
