import Config from "./modules/Config"
import { api, getCookie, setCookie } from "./Utils"


const getPageRelatedContent = (d) => {
    var defaultPage = null
    for (var page of d.content_pages) {
        if (page.url.toLowerCase() == 'default') {
            defaultPage = page
        }
        if (window.location.pathname.toLowerCase() == page.url.toLowerCase()) {
            if (page.header_logo && page.footer_logo)
                return page
        }
    }
    return defaultPage
}

addEventListener('show-promotion-popup', (data) => {
    console.log(data)
    if (data.id) {
        $.fancybox.open({
            src: `#${data.id}`,
            type: "inline",
            afterClose: function() {
                if (!data.name) {
                    return
                }

                api('post', '/should-cms-popup-be-shown', {
                    yid: getCookie('_ym_uid'),
                    name: data.name,
                })
            },
        });
    }
})

addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname.toLowerCase() == "/alerg/vk.html")
        document.querySelector('link[href="/css/index.css"]').remove()

    const data = {
        "cart": [],
        "country": "Россия",
        "promo": null,
        "kindergift": false,
    }
    fetch(Config.apiUrl + '/prices', {
        method: "post",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(data),
    }).then(r => r.json())
        .then((d) => {
            console.log(d)
            for (const promotion of d.promotions) {
                if (promotion.show_index_condition == "every_page_on_user_leave") {
                    const newPopup = document.createElement('div')
                    newPopup.classList.add('new-popup')
                    newPopup.classList.add(`promotion-popup-style${promotion.id}`)
                    newPopup.id = `promotion-popup-global-${promotion.id}`
                    newPopup.innerHTML = `<img class="desktop" src="${promotion.main_image_url}"><div class="body">${promotion.index_text}
                    <img class="mobile" src="${promotion.main_image_url}"><a class="order_btn promotion_btn promotion-btn-style${promotion.id}" href="${promotion.index_button_url}" target="_blank">${promotion.index_button_text}</a>
                </div>`
                    document.body.appendChild(newPopup)
                    $(document.body).bind("mouseleave", function (e) {
                        if (e.pageY - $(window).scrollTop() <= 1 && !getCookie('has_purchases')) {
                            setCookie('has_purchases', '1')
                            const e = new Event('show-promotion-popup')
                            e.id = newPopup.id
                            dispatchEvent(e)
                        }
                    });
                }
            }
        })

    document.cookie = 'gifts_cart='
    fetch(Config.apiUrl + '/cart-info', {
        method: "post",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            "uuid": getCookie('uuid')
        }),
    }).then(r => r.json()).then((d) => {
        console.log(`should clear cart: ${d.should_be_cleared}`)
        if (d.should_be_cleared) {
            sessionStorage.gifts_cart = []
            document.cookie = 'basket='
            document.cookie = 'phone='
            document.querySelectorAll('input[name="phone"]').forEach(phoneField => phoneField.value = '')
        }
        setCookie('enable_preorder', '1')
    })
})
