import {isPage, makeElement} from '../Utils'

export default {
    transformProducts(products, isEn) {
        return products.map(p => {
            return {
                productId: p.product_id,
                group: p.group,
                url: p.details_url,
                imageUrl: p.group == 'Набор' ? p.set_image_url : p.img,
                roundImageUrl: p.round_img,
                description: p.description,
                name: p.name,
                cerificateUrl: p.certificate_url,
                price: p.group == 'Набор' || p.is_big ? p.price.promo : (isEn ? p.price.en : p.price.ru),
                setPrice: isEn ? p.price.en : p.price.ru,
                currency: p.price.currency_symbol,
                group: p.group,
                setContent: p.set_content,
                htmlDescription: p.html_description,
                htmlShortDescription: p.html_short_description,
                leftQuantity: p.left_quantity,
                aClass: p.group == "Девайс" ? "bp-product-card b-product-card--device" : "b-product-card",
                phoneAClass: p.group == "Девайс" ? "b-related-product-card b-related-product-card--device" : "b-related-product-card",
                btnClass: p.group == "Девайс" ? "b-card-order__button b-card-order__button--device" : "b-card-order__button",
                showInMainPage: p.show_in_main_page,
                realisationType: p.realisation_type,
                mergedWithId: p.merged_with_id,
                showCartPopup: p.show_popup_in_cart,
                sideImgUrl: p.side_img,
                short_name: p.short_name,
                bigAdditionalName: p.big_additional_name || '',
                showInZeolitePage: p.show_in_zeolite_page,
                isBig: p.is_big,
            }
        })
    },
    displaySovps(products) {
        for (const product of products) {
            const sovp = document.createElement('div')
            sovp.id = `sovp${product.productId}`
            sovp.setAttribute('data-sovp-id', product.productId)
            sovp.classList.add('js-side-ov-panel')
            sovp.classList.add('b-side-ov-panel')
            sovp.classList.add('hidden-low')
	    sovp.classList.add('b-side-ov-panel-above')

	    if (['None', '/media/', '/media/None'].indexOf(product.sideImgUrl) != -1) {
		product.sideImgUrl = ''
	    }

            sovp.innerHTML = `
            <div class="js-side-ov-panel__body b-side-ov-panel__body">
                <div class="b-side-ov-panel__header">
                    <button class="js-side-ov-panel__close b-side-ov-panel__close">
                        <span class="b-side-ov-panel__close-txt">Закрыть</span>
    
                        <svg class="b-side-ov-panel__close-icon b-side-ov-panel__close-icon--m" width="30" height="30">
                            <use xlink:href="#close-circle-m-2"></use>
                        </svg>
    
    
                        <svg class="b-side-ov-panel__close-icon b-side-ov-panel__close-icon--d" width="30" height="30">
                            <use xlink:href="#close-circle-d-5"></use>
                        </svg>
    
                    </button>
                </div>
                <div class="b-side-ov-panel__body-inner">
                    <div class="h2">Перейти к оформлению?</div>
                    <div class="b-side-cart-added-notify">
                        <div class="row">
                            <div class="col-md-12">
                                <img loading="lazy" b-side-cart-added-notify__pic" src="${product.sideImgUrl}" alt="">
                            </div>
                            <div class="col-md-12">
                                <div class="b-side-cart-added-notify__buttons">
                                    <button
                                        class="js-side-ov-panel__close b-side-cart-added-notify__button-simple">Продолжить
                                        покупки</button>
                                    <a href="${product.productId == 20 || product.productId == 21 ? '/old_cart.html?utm_source=popup_mainpage_newyear&promo=ещедешевле' : 'old_cart.html'}" class="b-side-cart-added-notify__button">Перейти к оформлению</a>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>`
            document.body.append(sovp)
        }
    },
    removeMainHeaders(products) {
        const productNames = products.map(p => p.showInMainPage ? p.name + ' ' + p.description : '').join('; ').toLowerCase()
        const names = ['zeolite', 'complex', 'bottle', 'filter']
        const pages = ['zeolite', 'main2', 'main3', 'main4']
        for (var i = 0; i < names.length; i++) {
            const link = document.querySelector(`a[href="/${pages[i]}.html"]`)
            if (!link)
                continue
            if (productNames.indexOf(names[i]) < 0)
                link.parentElement.classList.add('hidden')
            else
                link.parentElement.classList.remove('hidden')
        }
    },
    displayBannerSlider() {
        const sliderElement = document.querySelector('.block_1_slider')
        if (!sliderElement) {
            return
        }

        const slider = $(sliderElement)
        slider.slick({
            lazyLoad: 'ondemand',
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: slider.children()[0].dataset.playspeed,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
        });
	let timeouts = []
        slider.on('afterChange', function(event, slick, currentSlide) {
            slider.slick('slickSetOption', 'autoplaySpeed', 1);
            const speed = $(slick.$slides.get(currentSlide)).data('playspeed')
            slider.slick('pause')
	    timeouts.slice(1).forEach(t => clearTimeout(t))
            timeouts.push(setTimeout(() => slider.slick('play'), speed))
        });
        slider.on('init', function(event, slick){
            console.log('products-loaded')
            dispatchEvent(new Event('products-loaded'))
        });
    },
    displayPromotions(promotions) {
        this.displayBannerSlider()
        var promotionPopupsContainer = document.getElementById('promotion-popups')
        if (promotionPopupsContainer) {
            promotionPopupsContainer.innerHTML = ''
	    }
        for (const promotion of promotions) {
            if (!promotion.show_main_popup || !promotionPopupsContainer)
                continue

            const popUpStyle = document.createElement('style')
            popUpStyle.innerHTML = `.promotion-popup-style${promotion.id} {background-color: ${promotion.popups_color};border-radius: ${promotion.popups_radius}px;}`
            document.head.appendChild(popUpStyle)
            const popUpBtnStyle = document.createElement('style')
            popUpBtnStyle.innerHTML = `.promotion-btn-style${promotion.id}:after {background: linear-gradient(90deg, ${promotion.popup_buttons_color1} 0.22%, ${promotion.popup_buttons_color2} 99.79%);};`
            document.head.appendChild(popUpBtnStyle)
            if (promotion.show_index_condition == "time") {
                const newPopup = document.createElement('div')
                newPopup.classList.add('new-popup')
                newPopup.classList.add(`promotion-popup-style${promotion.id}`)
                newPopup.id = `promotion-popup-${promotion.id}`
                newPopup.innerHTML = `<img loading="lazy" class="desktop" src="${promotion.main_image_url}"><div class="body">${promotion.index_text}
                        <img loading="lazy" class="mobile" src="${promotion.main_image_url}"><a class="order_btn promotion_btn promotion-btn-style${promotion.id}" href="${promotion.index_button_url}">${promotion.index_button_text}</a>
                    </div>`
                promotionPopupsContainer.appendChild(newPopup)
                setTimeout(() => {
                    const e = new Event('show-promotion-popup')
                    e.id = newPopup.id
                    dispatchEvent(e)
                }, promotion.show_cart_after_seconds * 1000)
            }
	    }
    },
    fetchMergedProducts(products) {
        var merged = []
        for (const product of products) {
            var inArray = false
            for (var item of merged) {
                if (item.indexOf(product.productId) == -1 && item.indexOf(product.mergedWithId) == -1)
                    continue

                inArray = true
                if (item.indexOf(product.productId) == -1) {
                    item.push(product.productId)
                }
                if (product.mergedWithId && item.indexOf(product.mergedWithId) == -1) {
                    item.push(product.mergedWithId)
                }
            }
            if (product.mergedWithId && !inArray) {
                merged.push([product.mergedWithId, product.productId])
            }
        }
        return merged
    },
    fetchMergedIds(products) {
        var mergedIds = []
        const push = (id) => {
            if (mergedIds.indexOf(id) == -1) {
                mergedIds.push(id)
            }
        }
        for (const product of products) {
            if (product.mergedWithId) {
                push(product.productId)
                push(product.mergedWithId)
            }
        }
        return mergedIds
    },
    displayIndexSimpleProducts(products) {
        const mergedIds = this.fetchMergedIds(products)

        var mainItemsContainer = document.querySelector('#main-products');
        if (!mainItemsContainer)
            return
        var bigItemsContainer = document.querySelector('#big-products');
        if (!bigItemsContainer)
            return

        mainItemsContainer.innerHTML = '';
        var specialContainer = document.querySelector('.block_special_offers')
        if (specialContainer) {
            specialContainer.innerHTML = '<h3 class="block_special_offers_heading">Спецпредложение</h3>';
        }
        var specialPopaps = document.querySelector('.popaps_more_container')
        // specialPopaps.innerHTML = ''

        // window.location.replace(window.location.origin + window.location.pathname)
        for (const product of products) {
            if (isPage(['/zeolite.html', '/z-celd.html']) && !product.showInZeolitePage)
                continue
            if (!product.showInMainPage || product.realisationType != "shop")
                continue


            if (product.group == "Набор") {
                if (mergedIds.indexOf(product.productId) != -1)
                    continue
                var item = document.createElement('div');
                item.className = 'block_special_offers_in'
                item.setAttribute('data-product-id', product.productId);
                item.innerHTML = `
                <div class="block_special_offers_in_img">
                    <img loading="lazy" src="${product.imageUrl}" alt="">
                </div>
                <div class="block_special_offers_in_txt">
                    <h4>${product.name} ${product.description}</h4>
                    <p>${product.htmlShortDescription}</p>
                    <div class="block_special_offers_in_txt_prise">
                    <h5>${product.price} <span>${product.currency}</span></h5>
                    <p>${product.setPrice} <span>${product.currency}</span></p>
                    </div>
                    <div class="block_special_offers_in_txt_btn">
                    <a onclick="rrApi.addToBasket(${product.productId});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'addToCart', params: { product_id: ${product.productId}}});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'purchase', params: { product_id: ${product.productId}}});" href="javascript:void()" class="js-card-order btn_order" data-id="${product.productId}">Заказать</a>
                    <a data-fancybox="" data-src="#more_popap${product.productId}" href="javascript:;" class="btn_more">Подробнее</a>
                    </div>
                </div>`
                specialContainer.appendChild(item);

                var popup = document.createElement('div');
                popup.className = 'more_popap'
                popup.id = `more_popap${product.productId}`
                popup.style = 'style="display: none;"'
                popup.innerHTML = `
                <div class="more_popap_in">
                    <div class="more_popap_in_flex">
                    <div class="block_special_offers_in_txt">
                        <h4>${product.name} ${product.description}</h4>
                        <div class="more_popap_in_info_in">
                        <h4>Состав набора:</h4>
                        ${product.setContent}
                        </div>
                        <div class="block_special_offers_in_txt_prise">
                        <h5>${product.price} <span>${product.currency}</span></h5>
                        <p>${product.setPrice} <span>${product.currency}</span></p>
                        </div>
                        <div class="block_special_offers_in_txt_btn">
                        <a onclick="rrApi.addToBasket(${product.productId});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'addToCart', params: { product_id: ${product.productId}}});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'purchase', params: { product_id: ${product.productId}}});" class="js-card-order btn_order" href="javascript:void()" data-id="${product.productId}">Заказать</a>
                        <a href="javascript:;" class="set_call_me_btn" data-product-name="${(product.name + ' ' + product.description).replaceAll('"', "'")}">Узнать, подойдет<br>ли мне</a>
                        </div>
                    </div>
                    <div class="more_popap_in_flex_img">
                        <img loading="lazy" src="${product.imageUrl}" alt="">
                    </div>
                    </div>
                    <div class="more_popap_in_info">
                    ${product.htmlDescription}
                    </div>
                </div>`
                specialPopaps.appendChild(popup)
                const more_btn = item.querySelector('.btn_more')
                more_btn.addEventListener('click', () => {
                    history.pushState({}, null, window.location.origin + window.location.pathname + `?open-popup=${product.productId}`);
                })

                continue
            }

            if (product.isbig) {
                var item = document.createElement('div');
                item.className = 'block_2_flex_in'
                item.setAttribute('data-product-id', product.productId);
                let pricePart = product.setPrice <= product.price ? `${product.price} <span class="ruble">${product.currency}</span>` : `<span class="big-product-actual-price">${product.price} <span class="ruble">${product.currency}</span></span><span class="big-product-fake-price">${product.setPrice} <span class="ruble">${product.currency}</span></span>`
                item.innerHTML = `
                    <div class="block_2_flex_in_img">
                        <a href="${product.url}">
                            <img loading="lazy" src="${product.imageUrl}" alt="">
                            <div class="base-product-orange-desc">${product.htmlShortDescription}</div>
                        </a>
                    </div>
                    <div class="base-product-description">
                        <h4>${product.name} <span class="base-product-orange-name">${product.bigAdditionalName}</span></h4>
                        <p><span>${product.description}</span></p>
                        <div class="gray-alpha80">${product.htmlDescription}</div>
                    </div>
                    <div class="base-product-buttons">
                        <h5>${pricePart}</h5>
                        <div class="block_2_flex_in_btn">

                        <div data-id="${product.productId}" class="js-card-order">
                            <div class="b-card-order__lvl1">
                                <span onclick="rrApi.addToBasket(${product.productId});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'addToCart', params: { product_id: ${product.productId}}});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'purchase', params: { product_id: ${product.productId}}});" class="js-card-order__button btn_1">Заказать</span>
                            </div>
                            <div class="b-card-order__lvl2">
                                <div class="js-quantity b-quantity">
                                    <div class="b-quantity__btn b-quantity__btn--down">-</div>
                                    <input class="js-checkout__quantity b-quantity__input" type="number" value="1" min="1">
                                    <div class="b-quantity__btn b-quantity__btn--up">+</div>
                                </div>
                            </div>

                        </div>

                        <a href="${product.url}" class="btn_1">Подробнее</a>
                    </div>
                    </div>`
                bigItemsContainer.appendChild(item);
                continue
            }

            var item = document.createElement('div');
            item.className = 'block_2_flex_in'
            item.setAttribute('data-product-id', product.productId);
                item.innerHTML = `
                    <div class="block_2_flex_in_img">
                    <a href="${product.url}"><img loading="lazy" src="${product.imageUrl}" alt=""></a>
                    </div>
                    <div class="base-product-description">
                        <h4>${product.name}</h4>
                        <p><span>${product.description}</span></p>
                    </div>
                    <div class="base-product-buttons">
                        <h5>${product.price} <span class="ruble">${product.currency}</span></h5>
                        <div class="block_2_flex_in_btn">

                        <div data-id="${product.productId}" class="js-card-order">
                            <div class="b-card-order__lvl1">
                                <span onclick="rrApi.addToBasket(${product.productId});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'addToCart', params: { product_id: ${product.productId}}});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'purchase', params: { product_id: ${product.productId}}});" class="js-card-order__button btn_1">Заказать</span>
                            </div>
                            <div class="b-card-order__lvl2">
                                <div class="js-quantity b-quantity">
                                    <div class="b-quantity__btn b-quantity__btn--down">-</div>
                                    <input class="js-checkout__quantity b-quantity__input" type="number" value="1" min="1">
                                    <div class="b-quantity__btn b-quantity__btn--up">+</div>
                                </div>
                            </div>

                        </div>

                        <a href="${product.url}" class="btn_1">Подробнее</a>
                    </div>
                    </div>`
            mainItemsContainer.appendChild(item);

            const sovp = document.createElement('div')
            sovp.id = `sovp${product.productId}`
            sovp.setAttribute('data-sovp-id', product.productId)
            sovp.classList.add('js-side-ov-panel')
            sovp.classList.add('b-side-ov-panel')
            sovp.innerHTML = `
            <div class="js-side-ov-panel__body b-side-ov-panel__body">
                <div class="b-side-ov-panel__header">
                    <button class="js-side-ov-panel__close b-side-ov-panel__close">
                        <span class="b-side-ov-panel__close-txt">Закрыть</span>
    
                        <svg class="b-side-ov-panel__close-icon b-side-ov-panel__close-icon--m" width="30" height="30">
                            <use xlink:href="#close-circle-m-2"></use>
                        </svg>
    
    
                        <svg class="b-side-ov-panel__close-icon b-side-ov-panel__close-icon--d" width="30" height="30">
                            <use xlink:href="#close-circle-d-5"></use>
                        </svg>
    
                    </button>
                </div>
                <div class="b-side-ov-panel__body-inner">
                    <div class="h2">Перейти к оформлению?</div>
                    <div class="b-side-cart-added-notify">
                        <div class="row">
                            <div class="col-md-12">
                                <img loading="lazy" class="b-side-cart-added-notify__pic" src="${product.sideImgUrl}" alt="">
                            </div>
                            <div class="col-md-12">
                                <div class="b-side-cart-added-notify__buttons">
                                    <button
                                        class="js-side-ov-panel__close b-side-cart-added-notify__button-simple">Продолжить
                                        покупки</button>
                                    <a href="${product.productId == 20 || product.productId == 21 ? '/old_cart.html?utm_source=popup_mainpage_newyear&promo=ещедешевле' : 'old_cart.html'}" class="b-side-cart-added-notify__button">Перейти к оформлению</a>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>`
            document.body.append(sovp)
        }
        var addToCart = new Event("add-to-cart-event-listeners");
        dispatchEvent(addToCart);


        document.querySelectorAll('.set_call_me_btn').forEach((e) => {
            e.addEventListener('click', () => {
                const popup = document.querySelector('#preorder-popup')

                $.fancybox.close();
                $.fancybox.open(popup)
            })
        })
    },
    displayMergedProducts(products) {
        const specialPopaps = document.querySelector('.popaps_more_container')
	    if (!specialPopaps)
	        return
        const specialContainer = document.querySelector('.block_special_offers')
        const merged = this.fetchMergedProducts(products)
        for (const merge of merged) {
            const product = products.find(val => val.productId == merge[1])
            // if (!product) {
            //     continue
            // }
            var item = null
            if (specialContainer && product.showInMainPage) {
                item = document.createElement('div');
                item.className = 'block_special_offers_in'
                item.setAttribute('data-product-id', product.productId);
                item.innerHTML = `
                        <div class="block_special_offers_in_img">
                        <img loading="lazy" src="/static/img/new-year/preview.webp" alt="">
                        </div>
                        <div class="block_special_offers_in_txt">
                        <h4>“Новогодний” набор</h4>
                        <p>Мы не можем подарить родным и близким здоровье. Но можем помочь им заботиться о своем организме и эффективно и безопасно! Мы собрали в подарочный набор наши любимые продукты, которые составляют отличный подарок и будут прекрасно работать в комплексе.
                        Очищайте организм вместе с Zeolite, насыщайте его с Mineral complex, пейте полезную воду и ешьте овощи с Water filter.<br><br><b>Наборы в новогоднем оформлении в продаже до 31.12.2023 г.</b></p>
                        <div class="block_special_offers_in_txt_prise">
                            <h5>${product.price} <span>${product.currency}</span></h5>
                            <p>${product.setPrice} <span>${product.currency}</span></p>
                        </div>
                        <div class="block_special_offers_in_txt_btn">
                            <a href="javascript:void()" onclick="rrApi.addToBasket(${product.productId});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'addToCart', params: { product_id: ${product.productId}}});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'purchase', params: { product_id: ${product.productId}}});" class="js-card-order btn_order" data-id="${product.productId}">Заказать</a>
                            <a data-fancybox="" data-src="#more_popap${product.productId}" href="javascript:;" class="btn_more">Подробнее</a>
                        </div>
                        </div>`
				item.querySelector('a[data-fancybox]').addEventListener('click', () => {
					window["rrApiOnReady"] = window["rrApiOnReady"] || []
					window["rrApiOnReady"].push(function() {
						try{ rrApi.view(product.productId); } catch(e) {}
					})

					try {
						_tmr.push({ type: "reachGoal", id: 3384650, value: product.price.ru, goal: "viewProduct", params: { product_id: product.product_id}});
					} catch (e) {}
				})
                specialContainer.appendChild(item);
            } else {
                for (const productId of merge) {
                    const currentProduct = products.find(p => p.productId == productId)
                    if (!currentProduct || !currentProduct.showCartPopup)
                        continue
                    const item = document.querySelector(`*[data-cart_line_id="${productId}"]`)
		            if (item) {
			            const nameCell = item.querySelector('.b-checkout-cart__item-line-cell--name')
			            nameCell.replaceWith($(`<a href="" data-fancybox="" data-src="#more_popap${merge[0]}" class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--name" type="a">
                        ${nameCell.innerHTML}
                        </a>`)[0])
		            }
                }
            }

            if (!product) {
                continue
            }

            var popup = document.createElement('div');
            popup.className = 'more_popap new-year-popup'
            popup.id = `more_popap${product.productId}`
            popup.style = 'style="display: none;"'
            popup.innerHTML = `
            <div class="more_popap_in">
                <div class="more_popap_in_flex">
                <div class="block_special_offers_in_txt new-year-popup-txt">
                <h4 class="desktop">“Новогодний” Набор</h4>
                <div class="more_popap_in_info_in new-year-popup-in">
                <p class="mobile-center">Подарить близким и родным здоровье невозможно. Но мы можем помочь им быть здоровыми как можно дольше и безопасно заботиться о своем организме.
                </p>
                <br>
                <b>Выберите дизайн упаковки</b>
                <div class="choice">
                <div>
                <img loading="lazy" src="/static/img/new-year/cover2.webp">
                <input data-num="2" class="cover-choice" type="radio" id="cover-choice-2" name="cover-choice" value="">
                </div>
                </div>
                </div>
                <div class="block_special_offers_in_txt_prise new-year-price-container">
                <h5>${product.price} <span>${product.currency}</span></h5>
                <p>${product.setPrice} <span>${product.currency}</span></p>
                </div>
                <div class="block_special_offers_in_txt_btn">
                <a class="js-card-order btn_order" href="javascript:void()" data-id="${product.productId}">Заказать</a>
                <a href="javascript:;" class="set_call_me_btn" data-product-name="${(product.name + ' ' + product.description).replaceAll('"', "'")}">Узнать подойдет<br>ли мне</a>
                </div>
                </div>
                <div class="more_popap_in_flex_img new-year-popup-img">
                <h4 class="new-year-set-header mobile">“Новогодний” Набор</h4>
                <img loading="lazy" class="preview" src="/static/img/new-year/main2.webp">
                    </div>
                    </div>
                    <div class="more_popap_in_info">
                    <center>
                    <img loading="lazy" class="center snowflake" src="/static/img/new-year/snowflake1.webp">
                    <br>
                    <b>Мы собрали в подарочный набор наши любимые продукты, которые составляют отличный подарок и будут прекрасно работать в комплексе.<br><br>
                    Он отлично подойдет для тех, кто только знакомится с нашими продуктами и хочет начать с чистого листа в январе!</b>
                    </center>
                    <h3 class="mobile-center">В составе набора:</h3>
                    <div class="content-wraper-rev">
                        <img loading="lazy" class="mobile-center" src="/static/img/new-year/zeolite.webp">
                        <div>
                            <p class="blue">1. Упаковка Zeolite — природный умный минерал с богатейшим микро— и макроэлементным составом.</p>
                            <p>Он выводит из организма токсины, тяжелые металлы, пестициды, микропластик, радиацию и наполняет его необходимыми макро- и микроэлементами.
                            <br>
                            <br>
                            Состав: 100% цеолит природный Холинского месторождения</p>
                        </div>
                    </div>
                    <div class="content-wraper">
                    <ul>
                        <li>усиливает антивирусную защиту</li>
                        <li>помогает восстановиться после стресса или болезни</li>
                        <li>убирает последствия интоксикации</li>
                        <li>помогает избавиться от различных форм аллергии</li>
                    </ul>
                    <ul>
                        <li>способствует укреплению иммунитета</li>
                        <li>сохраняет красоту кожи, ногтей и волос</li>
                        <li>налаживает работу ЖКТ</li>
                        <li>делает восстановление после беременности и родов более комфортным</li>
                    </ul>
                    </div>
                    <h3></h3>
                    <div class="content-wraper-rev">
                        <div><img loading="lazy" class="mobile-center" src="/static/img/new-year/mineral complex.webp" style="transform: translate(0, -20px);"></div>
                        <div>
                            <p class="blue">2. Бутылёк Mineral Complex — 100% натуральный минеральный комплекс для восстановления и поддержания энергии на клеточном уровне.</p>
                            <p>Состав: вода, обогащенная природным минералом цеолит; мох цетрария (ягель); трава левзея; настойка прополиса; комплекс гуминовых кислот; дигидрокверцетин (вытяжка из хвойных пород деревьев).</p>
                        </div>
                    </div>
                    <div class="content-wraper">
                    <ul>
                        <li>помогает вернуть здоровый сон, а по утрам — легко просыпаться</li>
                        <li>дает возможность сохранять красоту при высоком ритме жизни.</li>
                    </ul>
                    <ul>
                        <li>восстанавливает уровень вашей энергии: сил хватает на всё</li>
                        <li>поддерживает иммунитет</li>
                    </ul>
                    </div>
                    <h3></h3>
                    <div class="content-wraper-rev">
                        <div><img loading="lazy" class="mobile-center" src="/static/img/new-year/filter.webp" style="transform: translate(0, -20px);"></div>
                        <div>
                            <p class="blue">3. Фильтр для воды Water filter</p>
                            <p>Состав: 100% цеолит природный Холинского месторождения.</p>
                            <ul>
                                <li>Обладает высокой сорбционной способностью в отношении высокотоксичных тяжелых металлов, бактерий, нефтепродуктов, радионуклидов и так далее.</li>
                                <li>Восстанавливает минеральный состав воды (K, Ca, Mg, Zn и другие)</li>
                                <li>Снижает жесткость воды, способен предотвращать образование накипи на внутренней стороне поверхности электроприборов.</li>
                                <li>Подходит для замачивания овощей и фруктов от пестицидов</li>
                            </ul>
                        </div>
                    </div>
                    <center>
                        <div><img loading="lazy" class="center snowflake" src="/static/img/new-year/snowflake2.webp"></div>
                        <br>
                        <b>Очищайте организм вместе с Zeolite, насыщайте его с Mineral complex, пейте полезную воду и ешьте овощи с Water filter.<br>
                        Новогодний набор от O-complex — это наши продукты в подарочной упаковке для самых любимых людей, даже если подарок вы дарите сами себе.</b>
                    </center>
                </div>
            </div>`
            specialPopaps.appendChild(popup)
            if (specialContainer && product.showInMainPage) {
                const more_btn = item.querySelector('.btn_more')
                more_btn.addEventListener('click', () => {
                    history.pushState({}, null, window.location.origin + window.location.pathname + `?open-popup=${product.productId}`);
                })
            } else {
                popup.querySelector('.block_special_offers_in_txt_btn').classList.add('hidden')
            }

            const mainImage = document.querySelector('.new-year-popup-img')
            const preview = mainImage.querySelector('.preview')
            const leftArrow = mainImage.querySelector('.left')
            const rightArrow = mainImage.querySelector('.right')

            if (!leftArrow || !rightArrow) {
                continue
            }

            const setPreview = (num) => {
                const arrowToDisable = num == 1 ? leftArrow : rightArrow
                const arrowToEnable = num == 2 ? leftArrow : rightArrow
                if (arrowToDisable.classList.contains('disabled'))
                    return
                arrowToDisable.classList.add('disabled')
                arrowToEnable.classList.remove('disabled')
                preview.classList.add('transparent')
                setTimeout(() => {
                    preview.classList.remove('transparent')
                    preview.src = `/static/img/new-year/main${num}.webp`
                }, 300)
            }

            leftArrow.addEventListener('click', () => {
                setPreview(1)
            })

            rightArrow.addEventListener('click', () => {
                setPreview(2)
            })

            const choices = [...document.querySelectorAll('.cover-choice')]
            for (const choice of choices) {
                choice.addEventListener('click', () => {
                    const num = choice.getAttribute('data-num')
                    setPreview(num)
                    const productId = merge[num - 1]
                    const orderBtn = popup.querySelector('.js-card-order')
                    orderBtn.setAttribute('data-id', productId)
                    item.setAttribute('data-product-id', productId)
                    const itemOrderBtn = item.querySelector('.js-card-order')
                    itemOrderBtn.setAttribute('data-id', productId)
                })
            }
        }
    },
    display(productsRaw, promotions, isEn) {
        const products = this.transformProducts(productsRaw, isEn)
        this.removeMainHeaders(products)
        this.displayPromotions(promotions)
        this.displayIndexSimpleProducts(products)
        this.displayMergedProducts(products)
        this.displaySovps(products)


        const urlParams = new URLSearchParams(window.location.search)
        var openPopupId = urlParams.get('open-popup')
        urlParams.delete('open-popup')
        history.pushState({}, null, window.location.origin + window.location.pathname + '?' + urlParams);
        setTimeout(() => {
            if (openPopupId) {
                const e = new Event('show-promotion-popup')
                e.id = `more_popap${openPopupId}`
                dispatchEvent(e)
            }
        }, 400);

        var sideItemsContainer = document.querySelector('.b-related-product-cards');
        if (!sideItemsContainer)
            return

        var sideItemsContainerPhone = document.querySelector('.menu-list--mainside');
        if (!sideItemsContainerPhone)
            return

        var alreadyAdded = sideItemsContainer.querySelectorAll('.b-related-product-cards__item');
        var alreadyAddedIds = []
        alreadyAdded.forEach(a => alreadyAddedIds.push(parseInt(a.getAttribute('data-product-id'))))

        if (sideItemsContainerPhone.children.length <= 0) {
            var phoneItem = document.createElement("li");
            phoneItem.className = "menu-list__li";

            phoneItem.innerHTML = `<a href="index.html" class=" menu-list__item">
                <img loading="lazy" class="menu-list__item-pic" src="static/img/content/O.svg" alt="лого">О бренде
            </a>`;
            sideItemsContainerPhone.appendChild(phoneItem);
        }
        console.log(alreadyAddedIds)

        var isSomethingNew = false;
        for (const product of products) {
	    if (!product.showInMainPage)
                continue
            if (product.realisationType != 'shop' || product.group == 'Набор')
                continue
            if (alreadyAddedIds.indexOf(product.productId) != -1)
                continue
            isSomethingNew = true;

            var item = document.createElement('div');
            item.className = 'b-related-product-cards__item';
            item.setAttribute('data-product-id', product.productId);
            item.innerHTML = `<a href="${product.url}" class="${product.phoneAClass}">
                                <div class="b-related-product-card__wrpic">
                                    <img loading="lazy" class="b-related-product-card__pic" src="${product.imageUrl}">
                                </div>
                                <div class="b-related-product-card__body">
                                    <div class="b-related-product-card__caption">${product.name}</div>
                                    <p class="b-related-product-card__desc">${product.description}</p>
                                </div>
                                <div class="b-related-product-card__footer">
                                    <div><span class="b-related-product-card__link">Подробнее →</span>
                                    </div>
                                </div>
                            </a>
                            <div class="b-related-product-card-order">
                                <div onclick="rrApi.addToBasket(${product.productId});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'addToCart', params: { product_id: ${product.productId}}});_tmr.push({ type: 'reachGoal', id: 3384650, value: ${product.price.ru}, goal: 'purchase', params: { product_id: ${product.productId}}});" data-id="${product.productId}" id="product${product.productId}" class="js-card-order b-card-order"
                                    data-product-id="${product.productId}">
                                    <div class="b-card-order__lvl1">
                                        <span class="js-card-order__button b-card-order__button">Добавить</span>
                                    </div>
                                    <div class="b-card-order__lvl2">
                                        <div class="js-quantity b-quantity">
                                            <div class="b-quantity__btn b-quantity__btn--down">-</div>
                                            <input class="js-checkout__quantity b-quantity__input" type="number"
                                                value="0" min="0">
                                            <div class="b-quantity__btn b-quantity__btn--up">+</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="b-related-product-card-order__price">${product.price} <span class="ruble">${product.currency}</span>
                                </div>
                            </div>`;
            sideItemsContainer.appendChild(item);

            var phoneItem = document.createElement("li");
            phoneItem.className = "menu-list__li";

            phoneItem.innerHTML = `<a href="${product.url}" class=" menu-list__item">
                <img loading="lazy" class="menu-list__item-pic" src="${product.roundImageUrl}" alt="лого">${product.name}
            </a>`;
            sideItemsContainerPhone.appendChild(phoneItem);

        }

        if (isSomethingNew) {
            dispatchEvent(new Event("init-sovps"))
            var initMainJs = new Event("initSlider")
            setTimeout(() => {
                dispatchEvent(initMainJs)
            }, 500)
        }
    }
}
